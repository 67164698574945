<template>
  <b-card-code title="Settlement List">
      <div class="custom-search d-flex justify-content-start">
          <b-form-group>
              <div class="d-flex align-items-left">
                <span class="text-warning pl-1"><strong>Total Invoice: {{ totalInvoice }}</strong></span>
                 <span class="text-info pl-1"><strong>Pending Amount: {{ pending_amount }}</strong></span>
                 <span class="text-danger pl-1"><strong>Payable Amount: {{ total_payable_amount }}</strong></span>
              </div>
          </b-form-group>
      </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">

      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="false"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <!-- Column: Actions -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'id'" class="text-nowrap">
          <span>{{ props.row.id }}</span>
        </span>
        <span v-if="props.column.field == 'company_name'" class="text-nowrap">
          <span>{{ props.row.company_name }}</span>
        </span>

        <span v-if="props.column.field == 'total_invoice'" class="text-nowrap">
          <b-link
            :to="{
              name: 'admin-new-settlement',
              params: { ispId: props.row.id },
            }"
            class="font-weight-bold"
          >
            <span>{{ props.row.total_invoice }}</span>
          </b-link>
        </span>

        <span v-if="props.column.field == 'pending_amount'" class="text-nowrap">
          <span>{{ props.row.pending_amount }}</span>
        </span>
        <span v-if="props.column.field == 'charge_amount'" class="text-nowrap">
          <span>{{ props.row.charge_amount }}</span>
        </span>
        <span
          v-if="props.column.field == 'total_payable_amount'"
          class="text-nowrap"
        >
          <span>{{ props.row.total_payable_amount }}</span>
        </span>

        <div class="text-nowrap" v-if="props.column.field == 'actions'">
          <feather-icon
            :id="`invoice-row-${props.row.id}-preview-icon`"
            icon="Edit2Icon"
            size="16"
            class="mx-1"
            @click="
              $router.push({
                name: 'account-setting',
                query: { isp_id: props.row.id },
              })
            "
          />
        </div>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['100', '300', '500', '1000']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import {
  ISP_LIST,
  ADMIN_ISP_SETTLEMENT_LIST,
  ADMIN_ISP_LIST,
} from "@core/services/api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      toggle: false,
      isShow: false,
      pageLength: 100,
      dir: false,
      required,
      columns: [
        { field: "id", label: "ISP ID:", sortable: true },
        { field: "company_name", label: "ISP Name", sortable: true },
        { field: "total_invoice", label: "Pending Settelment", sortable: true },
        {
          field: "pending_amount",
          label: "Pending Amount",
          sortable: true,
        },
        {
          field: "charge_amount",
          label: "Charge",
        },
        {
          field: "total_payable_amount",
          label: "Total Payable",
        },
      ],
      rows: [],
      invoice_ids: [],
      isp_list: [],
      isp_id: "",
      searchTerm: "",
      from_date: "",
      to_date: "",
      bank_trans_id: null,
      remakr: null,
      status: "",
      updateStatus: "",
      UpdateBankTransTd: null,
      settlementId: "",
      rangeDate: null,
      totalInvoice:0,
        pending_amount:0,
        total_payable_amount:0,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.listSettlementData();
  },
  methods: {
    listSettlementData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ADMIN_ISP_SETTLEMENT_LIST, {
          params: {},
        })
        .then((res) => {
            console.log(res.data.data.isp_info)
          this.rows = res.data.data.isp_info;

            this.rows.forEach(item => {
                this.totalInvoice += item.total_invoice;
                this.pending_amount += item.pending_amount;
                this.total_payable_amount += item.total_payable_amount;
            })
        });
    },
    closeSideBar() {
      this.settlementId = null;
    },
    settlementUpdateIdSet(id) {
      this.settlementId = id;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
